<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          >
            <h6 class="mb-1">
              Tedarikçi Ekle
            </h6>
          </b-col>
        </b-row>
        <b-form-group label="Tedarikçi Kodu">
          <b-form-input
            v-model="formData.scode"
            placeholder="Tedarikçi Kodu"
          />
        </b-form-group>
        <b-form-group label="Başlık">
          <b-form-input
            v-model="formData.title"
            placeholder="Başlık"
          />
        </b-form-group>
        <b-form-group label="Telefon">
          <b-input-group>
            <b-input-group-prepend is-text>
              TR (+9)
            </b-input-group-prepend>
            <cleave
              v-model="formData.phone"
              class="form-control"
              :raw="false"
              :options="options.phone"
              placeholder="Telefon"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group label="E-Posta">
          <b-form-input
            v-model="formData.email"
            placeholder="E-Posta"
          />
        </b-form-group>
        <b-form-group label="Vergi Numarası">
          <cleave
            v-model="formData.tax_number"
            class="form-control"
            :raw="false"
            :options="options.phone"
            placeholder="Telefon"
          />
        </b-form-group>
        <b-form-group label="Vergi Dairesi">
          <b-form-input
            v-model="formData.tax_office"
            placeholder="Vergi Dairesi"
          />
        </b-form-group>
        <b-form-group label="Adres">
          <b-form-input
            v-model="formData.address"
            placeholder="Adres"
          />
        </b-form-group>
        <b-form-group label="Yetkili Adı Soyadı">
          <b-form-input
            v-model="formData.rname"
            placeholder="Yetkili Adı Soyadı"
          />
        </b-form-group>
        <b-form-group label="Not">
          <b-form-input
            v-model="formData.note"
            placeholder="Not"
          />
        </b-form-group>
      </b-card-text>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    Cleave,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        scode: null,
        title: null,
        phone: null,
        email: null,
        tax_office: null,
        tax_number: null,
        address: null,
        rname: null,
        note: null,
      },
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
        tax_number: {
          blocks: [4],
        },
      },
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['suppliers/getSupplierSave']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
          id_com_user: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {

  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('suppliers/supplierSave', this.formData)
    },
  },
}
</script>
